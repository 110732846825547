import React from 'react';
import './AppThankYou.css';

function AppThankYou() {
  return (
    <div className='thankyou-container'>
      <img src='/thankyou.jpg' alt='Thank You' className='thankyou-image' />
      <div className='thankyou-text font-calligraphic'>
        Mulțumim pentru prezență!
      </div>
    </div>
  );
}

export default AppThankYou;
